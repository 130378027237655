<template>
  <!-- { "id": "6149aa47a090df0023417dab", "body": "Test Comment", "user_voted": false, "vote_count": 0, "comment_count": 0, "author": "kannanm", "author_profile_image_url": "", "author_role": "Learner", "created_at": "2021-09-21T09:47:51Z", "updated_at": "2021-09-21T09:47:51Z" } -->
  <div
    :class="['response__box--wrapper', { 'response__edit--mode': editMode }]"
  >
    <div class="box-header">
      <div class="header-left">
        <div class="display-name">
          <div class="display-image">
            <b-img
              :src="authorProfileImagePath(response.author_profile_image_url)"
              alt="avatar"
            ></b-img>
          </div>
          <div class="box-author">
            {{ response.author }}
          </div>
          <div class="box-staff" v-if="response.author_is_staff">
            {{ $t("discussions.staff") }}
          </div>
          <div class="box-date">
            <span class="text-capitalize">
              {{
                response?.edited_by
                  ? setTextEdited
                    ? $t("discussions.post.edited_on")
                    : $t("discussions.post.edited")
                  : ""
              }}
            </span>
            {{
              response?.edited_by
                ? response?.updated_at
                : response?.created_at | humanizeNew
            }}
          </div>
        </div>
      </div>
      <div class="header-right">
        <div
          class="response-flag private-response"
          ref="private-response"
          v-if="response.is_private"
        >
          <img :src="privateResponseIcon" alt="Private Response" />
          <b-tooltip
            :target="() => $refs['private-response']"
            triggers="hover focus"
            placement="bottom"
          >
            <div class="cd__tooltip--inner">
              {{ $t("discussions.new_response.tooltip.private_response") }}
            </div>
          </b-tooltip>
        </div>
        <div
          class="response-flag report-response"
          ref="report-response"
          v-if="response.is_reported"
        >
          <img :src="reportedImage" alt="Reported" />
          <b-tooltip
            :target="() => $refs['report-response']"
            triggers="hover focus"
            placement="bottom"
          >
            <div class="cd__tooltip--inner">
              {{ $t("discussions.new_response.tooltip.report_response") }}
            </div>
          </b-tooltip>
        </div>
        <div
          class="response__action--dropdown"
          v-if="
            response?.can_edit || response?.can_delete || response?.can_report
          "
        >
          <b-dropdown
            size="lg"
            right
            variant="none"
            toggle-class="text-decoration-none"
            ref="lxCourseCardDropdown"
            no-caret
          >
            <template #button-content>
              <ImageButton
                :defaultIcon="actionIcon"
                :hoveredIcon="actionHoverIcon"
                :focusedIcon="actionHoverIcon"
                :pressedIcon="actionPressedIcon"
                altText="action"
              />
            </template>
            <b-dropdown-item
              class="dropdown-link"
              @click="showEditPostForm"
              v-if="response?.can_edit && !editMode"
            >
              <img :src="editIcon" alt="Edit" />
              {{ $t("general.edit") }}
            </b-dropdown-item>
            <b-dropdown-item
              class="dropdown-link"
              @click="discardEdit"
              v-if="editMode"
            >
              <img :src="discardEditIcon" alt="Discard" />
              {{ $t("general.discard_edits") }}
            </b-dropdown-item>
            <b-dropdown-item
              class="dropdown-link"
              variant="warning"
              @click="$emit('showReportModal', 'response', response.id)"
              v-if="response?.can_report"
            >
              <img :src="reportIcon" alt="Report" />
              {{ $t("general.report") }}
            </b-dropdown-item>
            <b-dropdown-item
              class="dropdown-link"
              variant="danger"
              v-if="response?.can_delete"
              @click="deleteResponse"
            >
              <img :src="deleteIcon" alt="Delete" />
              {{ $t("general.delete") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="box-content">
      <div :class="['response-form', { 'd-none': !editMode }]">
        <div
          :class="[
            'add__response--inner',
            { 'height-increased': isHeightIncreased }
          ]"
        >
          <div class="response__input--wrapper">
            <quill-editor
              v-model="responseBody"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              :class="[
                'add__response--input',
                {
                  'toxic-input': isResponseToxic,
                  'input-focused': isEditorFocused || responseBody !== ''
                }
              ]"
            >
            </quill-editor>
            <div class="add-emoji">
              <ImageButton
                :defaultIcon="addEmojiIcon"
                :hoveredIcon="addEmojiHoverIcon"
                :focusedIcon="addEmojiHoverIcon"
                :pressedIcon="addEmojiPressedIcon"
                altText="Emoji"
              />
            </div>
          </div>
          <LxpLoader class="response-spinner" v-if="isResponseLoading" />
          <div
            :class="[
              'submit-response',
              {
                'disable-response': isResponseDisable,
                hovered: isAddResponseIconHovered || isResponseDropdownOpen
              }
            ]"
            ref="dropdownContainer"
            v-else-if="
              getUserName === response?.author &&
                getSelectedDiscussionPost?.is_facilitator
            "
          >
            <img
              :src="submitResponseIcon"
              alt="Submit"
              @mouseover="isAddResponseIconHovered = true"
              @mouseout="isAddResponseIconHovered = false"
              @click="responseDropdown"
            />
            <div class="share__response--list" v-if="isResponseDropdownOpen">
              <Button
                class="share__list--item"
                type="submit"
                @click="setSubmitType('publicly')"
              >
                <span class="title">
                  {{ $t("discussions.new_response.button.publicly.title") }}
                </span>
                <span class="subtext">
                  {{ $t("discussions.new_response.button.publicly.content") }}
                </span>
              </Button>
              <Button
                class="share__list--item"
                type="submit"
                @click="setSubmitType('privately')"
              >
                <span class="title">
                  {{ $t("discussions.new_response.button.privately.title") }}
                </span>
                <span class="subtext">
                  {{ $t("discussions.new_response.button.privately.content") }}
                </span>
              </Button>
            </div>
          </div>
          <button
            :disabled="isResponseDisable"
            :class="[
              'submit-response',
              {
                hovered: isAddResponseIconHovered
              }
            ]"
            type="submit"
            @click="updateResponse"
            v-else
          >
            <img
              :src="submitResponseIcon"
              alt="Submit"
              @mouseover="isAddResponseIconHovered = true"
              @mouseout="isAddResponseIconHovered = false"
            />
          </button>
        </div>
        <div class="error-text toxic-error" v-if="isResponseToxic">
          {{ $t("discussions.new_post.toast.toxic.response.note") }}
        </div>
        <div class="error-text" v-if="showError && responseBody !== ''">
          {{ $t("discussions.new_response.error.title") }}
        </div>
        <div
          class="counter-error"
          v-if="responseBodyLength >= textLimit && responseBody !== ''"
        >
          {{ responseBodyLength }} / {{ textLimit }}
        </div>
      </div>
      <div
        :class="['view-response', { 'd-none': editMode }]"
        ref="viewResponse"
      >
        {{ injectIntoDiv(responseBody) }}
      </div>
      <ImageButton
        v-if="!editMode && !showFullContent && textIsTruncated"
        :defaultIcon="viewMoreIcon"
        :pressedIcon="viewMorePressedIcon"
        altText="View More"
        @icon-click="handleIconClick"
        class="view-more"
      />
    </div>
    <div class="box-footer">
      <div class="footer-inner footer-left">
        <UpvoteDownvote
          :isPostClosed="isPostClosed"
          :voteCount="voteCount"
          :userVoted="userVoted"
          @toggleLike="toggleVote"
        />
      </div>
      <div class="footer-inner footer-center">
        <div class="add-comment">
          <img :src="addCommentIcon" alt="Comment" />
          <span>{{ $t("discussions.new_comment.button.title") }}</span>
        </div>
      </div>
      <div class="footer-inner footer-right">
        <div class="show-comments">
          <div
            class="toggle-comments"
            v-if="showCommentCount === true"
            href="#"
            @click.stop="handleShowAllReplies"
          >
            <span v-if="toggleShowAllReplies === true">
              <img :src="hideCommentIcon" alt="Hide Comment" />
              <span>{{ $t("discussions.new_comment.hide") }}</span>
            </span>
            <span v-else>
              <img :src="showCommentIcon" alt="Show Comment" />
              <span>
                {{
                  comments.count > response.comment_count
                    ? formatCount(comments.count)
                    : formatCount(response.comment_count)
                }}
              </span>
            </span>
          </div>
          <div class="no-comment" v-else>
            <img :src="noCommentIcon" alt="No Comment" />
            <span>0</span>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!isPostClosed">
      <DiscussionAddComment
        :responseId="response.id"
        @commentAdded="updateComments"
      />
    </template>
    <div class="comment__box--wrapper" v-if="toggleShowAllReplies === true">
      <template v-if="comments?.count > 0">
        <CommentCard
          :comment="comment"
          v-for="comment in comments.results"
          :key="comment.id"
        />
      </template>
      <template v-if="isCommentsLoading">
        <LxpLoader />
      </template>
      <div
        class="load-more"
        v-if="comments.next_page != null && isCommentsLoading === false"
        @click="loadMoreComments"
      >
        {{ $t("general.load_more") }}
      </div>
    </div>
  </div>
</template>
<script>
import getUsernameWithRole from "@/core/mixins/getUsernameWithRole.js";
import authorProfileImagePath from "@/core/mixins/authorProfileImagePath.js";
import countFormatter from "@/core/mixins/countFormatter.js";
import CommentCard from "./CommentCard.vue";
import DiscussionAddComment from "./DiscussionAddComment.vue";
import UpvoteDownvote from "./UpvoteDownvote.vue";
import { LxpLoader } from "didactica";
import { mapGetters } from "vuex";
import moment from "moment";
import { quillEditor } from "vue-quill-editor";
import "quill-mention";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
const MAX_TRUNCATED_HEIGHT = 72;

export default {
  props: {
    response: {
      type: Object,
      required: true
    },
    isPostClosed: {
      type: Boolean,
      default: false
    }
  },
  mixins: [getUsernameWithRole, authorProfileImagePath, countFormatter],
  components: {
    CommentCard,
    DiscussionAddComment,
    UpvoteDownvote,
    LxpLoader,
    quillEditor
  },
  data() {
    return {
      comments: {
        count: 0,
        next_page: null,
        previous_page: null,
        results: []
      },
      toggleShowAllReplies: false,
      voteCount: 0,
      userVoted: false,
      addCommentIcon: require("@/assets/images/vle/forums/response/add-comment.svg"),
      privateResponseIcon: require("@/assets/images/vle/forums/response/private-response.svg"),
      noCommentIcon: require("@/assets/images/vle/forums/comment/no-comment.svg"),
      hideCommentIcon: require("@/assets/images/vle/forums/comment/hide-comment.svg"),
      showCommentIcon: require("@/assets/images/vle/forums/comment/show-comment.svg"),
      editIcon: require("@/assets/images/vle/forums/dropdown/edit.svg"),
      discardEditIcon: require("@/assets/images/vle/forums/dropdown/discard.svg"),
      deleteIcon: require("@/assets/images/vle/forums/dropdown/delete.svg"),
      reportIcon: require("@/assets/images/vle/forums/dropdown/report.svg"),
      reportedImage: require("@/assets/images/vle/forums/report.svg"),
      defaultGreyAvatar: require("@/assets/images/menu/avatar-grey.svg"),
      addResponseIcon: require("@/assets/images/vle/forums/response/response-submit.svg"),
      addResponseIconHover: require("@/assets/images/vle/forums/response/response-submit-hover.svg"),
      addResponseDisabledIcon: require("@/assets/images/vle/forums/response/response-submit-disabled.svg"),
      addEmojiIcon: require("@/assets/images/vle/forums/response/add-emoji.svg"),
      addEmojiHoverIcon: require("@/assets/images/vle/forums/response/add-emoji-hover.svg"),
      addEmojiPressedIcon: require("@/assets/images/vle/forums/response/add-emoji-pressed.svg"),
      viewMoreIcon: require("@/assets/images/vle/forums/response/view-more.svg"),
      viewMorePressedIcon: require("@/assets/images/vle/forums/response/view-more-pressed.svg"),
      actionIcon: require("@/assets/images/vle/forums/response/action.svg"),
      actionHoverIcon: require("@/assets/images/vle/forums/response/action-hover.svg"),
      actionPressedIcon: require("@/assets/images/vle/forums/response/action-pressed.svg"),
      showFullContent: false,
      isCommentsLoading: true,
      editMode: false,
      textLimit: 1500,
      isAddResponseIconHovered: false,
      isResponseLoading: false,
      responseBody: "",
      responseBodyLength: 0,
      editorOption: {
        theme: "bubble",
        placeholder: "",
        disable: true,
        modules: {
          toolbar: false,
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            showDenotationChar: false,
            positioningStrategy: "fixed",
            source: (searchTerm, renderList) => {
              let values = this.getMentionsList.map(item => ({
                id: item.username,
                value: item.display_name
              }));

              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++) {
                  if (
                    values[i].display_name
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase())
                  ) {
                    matches.push(values[i]);
                  }
                }
                renderList(matches, searchTerm);
              }
            },
            renderItem: user => {
              const img = this.fetchUserAvatar(user);
              return `<span class="img"><img src="${img}" alt="${user.value}" /></span>
                      <span class="text">${user.value}</span>`;
            }
          }
        }
      },
      isResponseToxic: false,
      showError: false,
      editorHeight: 0,
      isHeightIncreased: false,
      isEditorFocused: false,
      observer: null,
      textIsTruncated: false,
      submitType: "",
      mentionList: [],
      isResponseDropdownOpen: false,
      isResponseDisable: false
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.initializeLocalVariables();
    if (this.toggleShowAllReplies) {
      this.loadMoreComments();
    }
    this.updateResponseBodyText();
    this.updateResponseBodyLength();
    this.setupResizeObserver();
  },
  beforeUnmount() {
    this.observer?.disconnect?.();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  computed: {
    ...mapGetters([
      "getMentionsList",
      "getSelectedDiscussionPost",
      "getUserName"
    ]),
    author() {
      return this.getUsernameWithRole(
        this.response.author,
        this.response.author_is_staff
      );
    },
    showCommentCount() {
      return this.response.comment_count > 0 || this.comments.count > 0;
    },
    isTextLimitExceeded() {
      return this.responseBodyLength > this.textLimit;
    },
    submitResponseIcon() {
      if (this.isResponseDisable) {
        return this.addResponseDisabledIcon;
      } else if (this.isAddResponseIconHovered || this.isResponseDropdownOpen) {
        return this.addResponseIconHover;
      } else {
        return this.addResponseIcon;
      }
    },
    setTextEdited() {
      return (
        this.$options.filters.humanizeNew(this.response.updated_at) ===
        moment(this.response.updated_at).format("MMM DD, YYYY")
      );
    }
  },
  methods: {
    setupResizeObserver() {
      this.observer = new ResizeObserver(entries => {
        const readOnlyResponse = entries[0];
        const height = readOnlyResponse.contentRect.height;
        if (this.showFullContent) {
          this.$refs.viewResponse.classList.remove("line-clamp", "clamp3");
          this.textIsTruncated = false;
          return;
        }
        if (height === 0) {
          this.textIsTruncated = false;
          return;
        }
        if (height > MAX_TRUNCATED_HEIGHT) {
          this.$refs.viewResponse.classList.add("line-clamp", "clamp3");
          this.textIsTruncated = true;
          return;
        }
      });
      const readOnlyResponseElement = this.$refs.viewResponse;
      this.observer.observe(readOnlyResponseElement);
    },
    extractMentionedUsers() {
      const mentionedUsers = [];
      const textDelta = this.$refs?.myQuillEditor?.quill?.getContents();
      if (textDelta.ops) {
        textDelta.ops.forEach(item => {
          if (item.insert && item.insert.mention) {
            mentionedUsers.push(item.insert.mention);
          }
        });
      }
      return mentionedUsers;
    },
    updateResponseBodyLength() {
      const text = this.$refs?.myQuillEditor?.quill?.getText();
      const mentionedUsers = this.extractMentionedUsers();
      const mentionedUserCharacters = mentionedUsers.reduce(
        (acc, item) => acc + item.value.length,
        0
      );
      const totalMentionsArray = mentionedUsers.map(user => user.id);
      const totalLength = (text?.length ?? 0) + mentionedUserCharacters - 1; // Don't count \n at the end
      this.responseBodyLength = totalLength;
      this.mentionList = totalMentionsArray;
    },
    updateResponse() {
      this.showError = false;
      this.isAddResponseIconHovered = false;
      this.isResponseLoading = true;
      let isPrivateValue;
      if (this.getUserName === this.response?.author) {
        isPrivateValue = this.getSelectedDiscussionPost?.is_facilitator
          ? this.submitType === "privately"
          : this.mentionList.length > 0;
      } else {
        isPrivateValue = this.response?.is_private;
      }
      let obj = {
        body: this.responseBody,
        mentioned_users: this.mentionList.join(","),
        is_private: isPrivateValue
      };
      const formData = new FormData();
      for (const [key, value] of Object.entries(obj)) {
        formData.append(key, value);
      }
      this.$store
        .dispatch("updatePostResponse", {
          params: formData,
          responseId: this.response.id
        })
        .then(() => {
          this.isResponseLoading = false;
          this.isResponseDropdownOpen = false;
          this.isResponseToxic = false;
          this.editMode = false;
          this.$emit("reloadResponses");
          this.showFullContent = false;
        })
        .catch(err => {
          this.isResponseLoading = false;
          this.isResponseDropdownOpen = false;
          if (err.response.data.toxic) {
            this.isResponseToxic = true;
            this.$bvToast.show("toxic-response-toast");
          } else {
            this.showError = true;
          }
        });
    },
    onEditorBlur() {
      this.isEditorFocused = false;
      if (this.responseBody && this.editMode) {
        this.checkToxicLanguage();
      } else {
        this.isResponseToxic = false;
      }
    },
    onEditorFocus() {
      this.isEditorFocused = true;
    },
    onEditorReady() {
      this.updateEditorHeight();
    },
    initializeLocalVariables() {
      this.voteCount = this.response.vote_count;
      this.userVoted = this.response.user_voted;
    },
    async toggleVote() {
      return new Promise(resolve => {
        let obj = {
          courseId: this.$route.params.id,
          responseId: this.response.id
        };
        if (this.userVoted) {
          this.$store.dispatch("deleteResponseVote", obj).then(voteCount => {
            this.voteCount = voteCount;
            this.userVoted = false;
            resolve();
          });
        } else {
          this.$store.dispatch("createResponseVote", obj).then(voteCount => {
            this.voteCount = voteCount;
            this.userVoted = true;
            resolve();
          });
        }
      });
    },
    async handleShowAllReplies() {
      // Make an API call for the first-time only
      if (this.comments.count === 0) {
        await this.showAllReplies();
      }
      this.toggleShowAllReplies = !this.toggleShowAllReplies;
    },
    updateComments() {
      this.showAllReplies();
    },
    loadMoreComments() {
      this.showAllReplies({
        responseId: this.response.id,
        page: this.comments.next_page
      });
    },
    async showAllReplies(
      params = {
        responseId: this.response.id,
        page: 1
      }
    ) {
      return new Promise(resolve => {
        this.isCommentsLoading = true;
        this.$store
          .dispatch("getPostResponseComments", params)
          .then(response => {
            if (response.previous_page !== null) {
              response.results = [
                ...this.comments.results,
                ...response.results
              ];
            }
            this.comments = response;
            this.isCommentsLoading = false;
            resolve();
          });
      });
    },
    handleIconClick() {
      if (!this.showFullContent) {
        this.showFullContent = true;
        this.$refs.viewResponse.classList.remove("line-clamp", "clamp3");
      }
    },
    fetchUserAvatar(user) {
      const username = user.id;
      const _user = this.getMentionsList?.find(
        item => item.username === username
      );
      if (_user.profile_image_url) {
        return _user.profile_image_url;
      } else {
        return this.defaultGreyAvatar;
      }
    },
    showEditPostForm() {
      this.editMode = true;
      this.checkDisableBtn();
    },
    discardEdit() {
      this.updateResponseBodyText();
      this.editMode = false;
    },
    injectIntoDiv(data) {
      const element = this.$refs.viewResponse;
      if (element) {
        element.innerHTML = data;
      }
    },
    renderHTML(data) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/html");
      const textContent = doc.body.textContent;
      return textContent;
    },
    async checkToxicLanguage() {
      let toxicText = "";
      toxicText = this.renderHTML(this.responseBody);
      const response = await this.$store.dispatch("checkToxicLanguage", {
        text: toxicText,
        language: ""
      });
      let isToxic = Math.floor(response.toxicity.score * 10) / 10 > 0.5;
      this.isResponseToxic = isToxic;
      if (isToxic) {
        this.$bvToast.show("toxic-response-toast");
      }
    },
    deleteResponse() {
      this.$store
        .dispatch("deletePostResponse", {
          responseId: this.response.id
        })
        .then(() => {
          this.$emit("reloadResponses");
        });
    },
    updateEditorHeight() {
      const editorElement = this.$refs?.myQuillEditor?.quill?.root;
      if (editorElement && this.responseBody) {
        const height = editorElement.clientHeight;
        this.editorHeight = height;
        this.isHeightIncreased = height > 40;
      } else {
        this.editorHeight = 0;
        this.isHeightIncreased = false;
      }
    },
    setSubmitType(type) {
      this.submitType = type;
      this.updateResponse();
    },
    responseDropdown() {
      this.isResponseDropdownOpen = !this.isResponseDropdownOpen;
    },
    handleClickOutside(event) {
      const dropdownContainer = this.$refs.dropdownContainer;
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        this.isResponseDropdownOpen = false;
      }
    },
    updateResponseBodyText() {
      if (this.response.body.includes('class="mention"')) {
        const regex = /<\/span> (<\/p>)/g;
        this.responseBody = this.response.body.replace(
          regex,
          "</span>&nbsp;$1"
        );
      } else {
        this.responseBody = this.response.body;
      }
    },
    checkDisableBtn() {
      this.isResponseDisable =
        !this.responseBody ||
        this.responseBodyLength > this.textLimit ||
        this.$refs?.myQuillEditor?.quill?.getText?.()?.trim?.()?.length === 0;
    }
  },
  watch: {
    responseBody() {
      this.updateEditorHeight();
      this.updateResponseBodyLength();
      this.checkDisableBtn();
    }
  }
};
</script>
<style lang="scss" scoped>
.response__box--wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $brand-neutral-200;
  border-radius: 16px;
  padding: 16px;
  background-color: $brand-neutral-0;
  &:not(:first-child) {
    margin-top: 32px;
  }
  .box-header {
    @include horizontal-space-between;
    .header-right {
      @include flex-horizontal-end;
      > * {
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
    .display-name {
      @include flex-center;
      .display-image {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        @include flex-horizontal-center;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .box-author {
      margin-left: 8px;
      @include label-large($brand-neutral-700, 500);
    }
    .box-staff {
      border-radius: 8px;
      border: 1px solid $brand-secondary1-400;
      background-color: $brand-secondary1-50;
      @include label-large($brand-secondary1-400, 500);
      padding: 3px 7px;
      margin-left: 8px;
    }
    .box-date {
      padding-left: 24px;
      @include label-large($brand-neutral-300, 500);
      position: relative;
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $brand-neutral-700;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
      }
    }
    .response-flag {
      width: 28px;
      min-width: 28px;
      height: 28px;
      border-radius: 8px;
      @include flex-horizontal-center;
      cursor: pointer;
      &.private-response {
        &:hover {
          background-color: $brand-secondary1-50;
        }
      }
      &.report-response {
        &:hover {
          background-color: $forums-secondary-bg;
        }
      }
    }
  }
  .box-content {
    margin-top: 8px;
    @include body-regular;
    word-break: break-word;
    position: relative;
    .view-response {
      &.line-clamp {
        padding-right: 32px;
      }
    }
    .view-more {
      position: absolute;
      bottom: -3px;
      right: 0px;
    }
  }
  .box-footer {
    margin-top: 8px;
    padding-top: 12px;
    border-top: 1px solid $brand-neutral-200;
    @include flex-center;
    .footer-inner {
      flex: 1;
      display: flex;
      &.footer-center {
        justify-content: center;
      }
      &.footer-right {
        justify-content: flex-end;
      }
    }
    .add-comment {
      @include flex-center;
      @include label-large($brand-primary, 500);
      span {
        margin-left: 8px;
      }
    }
    .show-comments {
      .toggle-comments {
        padding: 8px;
        cursor: pointer;
        > span {
          @include flex-center;
          @include label-large($brand-primary, 500);
          span {
            margin-left: 8px;
          }
        }
      }
      .no-comment {
        @include flex-center;
        @include label-large($brand-neutral-300, 500);
        padding: 8px;
        span {
          margin-left: 8px;
        }
      }
    }
  }
  .comment__box--wrapper {
    border-top: 1px solid $brand-neutral-200;
    padding-top: 24px;
    padding-left: 48px;
    margin-top: 12px;
    .lxp-loader {
      margin-top: 20px;
    }
    .load-more {
      @include body-regular($brand-primary-400);
      margin-top: 20px;
      text-align: center;
      cursor: pointer;
    }
  }
  &.response__edit--mode {
    .response-form {
      display: flex;
      flex-direction: column;
    }
    .add__response--inner {
      @include flex-center;
      .response__input--wrapper {
        width: 100%;
        position: relative;
        @include flex-center;
      }
      .add__response--input {
        border: 2px solid transparent;
        border-radius: 24px;
        background-color: $brand-primary-75;
        padding: 4px 60px 4px 12px;
        min-height: 48px;
        width: 100%;
        color: $brand-neutral-700;
        letter-spacing: 0.1px;
        word-break: break-word;
        &:hover,
        &.input-focused {
          background-color: $brand-primary-100;
        }
        .ql-editor {
          padding: 0;
        }
        &.toxic-input {
          border-color: $brand-warning;
        }
      }
      .add-emoji {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
      }
      .submit-response {
        border: none;
        cursor: pointer;
        padding: 0;
        background-color: transparent;
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        @include flex-horizontal-center;
        margin-left: 12px;
        margin-right: 4px;
        position: relative;
        img {
          width: 100%;
        }
        &:hover {
          background-color: $brand-primary-100;
          outline: 4px solid $brand-primary-100;
        }
        &:disabled,
        &.disable-response {
          pointer-events: none;
        }
      }
      .response-spinner {
        margin-left: 12px;
        margin-right: 4px;
      }
      &.height-increased {
        align-items: flex-end;
        .add-emoji {
          top: auto;
          transform: none;
          bottom: 4px;
        }
        .submit-response,
        .response-spinner {
          margin-bottom: 7px;
        }
      }
    }
    .share__response--list {
      position: absolute;
      width: 263px;
      left: calc(100% + 8px);
      top: -2px;
      border-radius: 8px;
      background-color: $brand-neutral-0;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      padding: 4px;
      .share__list--item {
        border-radius: 4px;
        border: none;
        padding: 8px;
        @include flex-column-start;
        text-align: left;
        background-color: transparent;
        width: 100%;
        height: auto;
        &:focus,
        &:active,
        &:visited {
          background-color: transparent !important;
        }
        &:hover {
          background-color: $brand-primary-100;
          box-shadow: none;
        }
        .title {
          @include body-medium;
          color: $brand-neutral-700;
        }
        .subtext {
          margin-top: 4px;
          @include body-medium;
          color: $brand-neutral-300;
        }
      }
    }
    .error-text {
      @include label-medium;
      letter-spacing: 0px;
      color: $brand-error-400;
      margin-top: 8px;
      &.toxic-error {
        color: $brand-neutral-300;
      }
    }
    .counter-error {
      padding: 8px 8px 0px 0px;
      display: flex;
      justify-content: flex-end;
      width: calc(100% - 32px);
      @include label-medium;
      color: $brand-error-400;
    }
  }
}
[dir="rtl"] {
  .response__box--wrapper {
    .box-header {
      .box-author {
        margin-left: 0px;
        margin-right: 8px;
      }
      .box-date {
        padding-left: 0px;
        padding-right: 24px;
        &:before {
          left: auto;
          right: 10px;
        }
      }
    }
    .box-footer {
      .show-comments {
        .toggle-comments {
          > span {
            span {
              margin-left: 0px;
              margin-right: 8px;
            }
          }
        }
      }
      .add-comment {
        span {
          margin-left: 0px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
